import { Column, Container } from '@123-front/ui-kit';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import FlexCenter from '../../../components/flex-center';
import Hero from '../../../components/hero';
import Layout from '../../../components/layout';
import SEO from '../../../components/seo';
import ShopLeadForm, { ShopLeadData } from '../../../components/shop-lead-form';
import SuccessNotice from '../../../components/success-notice';

const defaultLead: ShopLeadData = {
  category: '',
  province: '',
  name: '',
  email: '',
  phone: '',
};

const noFollowMetaTag = [
  {
    name: 'robots',
    content: 'noindex,nofollow',
  }
];

const ShopInsuranceLandingAr: React.FC = () => {
  const [submitted, submit] = useState(false);
  const [leadInfo, setLeadInfo] = useState(defaultLead);
  const backgrounds = useStaticQuery(graphql`
    query {
      mobile: s3Object(localFile: { name: { eq: "photo_business-leads_hero_small" } }) {
        localFile {
          childImageSharp {
            fluid(maxHeight: 280) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      desktop: s3Object(localFile: { name: { eq: "photo_business-leads_hero_large" } }) {
        localFile {
          childImageSharp {
            fluid(maxHeight: 400) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
    }
  `);

  return (
    <Layout gtmId="GTM-N8SRQZL">
      <SEO
        title="Cotizar Seguro Integral de Comercio"
        siteTitle="123Seguro Argentina"
        description="¡Acá vas a poder Cotizar Seguro Integral de Comercio en pocos pasos! En 123 y Chubb te ofrecemos los mejores precios en seguros para Locales"
        meta={noFollowMetaTag}
      />
      <Hero
        desktopBackground={backgrounds.desktop.localFile.childImageSharp.fluid}
        mobileBackground={backgrounds.mobile.localFile.childImageSharp.fluid}
        title="SEGUROS PARA COMERCIO 123SEGURO"
        subtitle="Seguros para comercios\ndesde $30 por día"
      />
      <Container>
        <FlexCenter>
          <Column desktop="6">
            {submitted ? (
              <SuccessNotice
                solicitudText="¡Solicitud enviada!"
                verifyText="¿No son esos tus datos?"
                noticeText={`Recibimos tu solicitud de cotización para tu comercio. Nos contactaremos con vos por WhatsApp (+549 ${leadInfo.phone}) o por mail (${leadInfo.email})`}
                onRequestChange={() => submit(false)}
                changeText="Modificarlos"
              />
            ) : (
              <ShopLeadForm
                {...leadInfo}
                countryCode="ar"
                onRegistration={(formValues) => {
                  setLeadInfo(formValues);
                  submit(true);
                }}
              />
            )}
          </Column>
        </FlexCenter>
      </Container>
    </Layout>
  );
};

export default ShopInsuranceLandingAr;

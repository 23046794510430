import {
  Alert,
  Button,
  Input,
  isValidEmail,
  isValidNumber,
  isValidRequired
} from '@123-front/ui-kit';
import Axios from 'axios';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import Form from './form';
import './form.css';


export interface ShopLeadData {
  category: string;
  province: string;
  name: string;
  email: string;
  phone: string;
}

const initialErrors = {
  category: null,
  province: null,
  name: null,
  email: null,
  phone: null,
};

interface FormProps extends ShopLeadData {
  countryCode: string;
  onRegistration: (formData: ShopLeadData) => void;
}

const isEmpty = (object: Record<string, unknown>) => Object.values(object).every((value) => !value);

const ShopLeadForm: React.FC<FormProps> = ({ onRegistration, ...props }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            apiDomain
          }
        }
      }
    `,
  );
  const [category, setCategory] = useState(props.category);
  const [province, setProvince] = useState(props.province);
  const [name, setName] = useState(props.name);
  const [email, setEmail] = useState(props.email);
  const [phone, setPhone] = useState(props.phone);
  const [errors, setErrors] = useState(initialErrors);
  const [processing, setProcessing] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const getErrors = () => ({
    category: !isValidRequired(category.trim())
      ? 'Ingresá el rubro de tu comercio; ej.: Gastronomía'
      : '',
    province: !isValidRequired(province.trim()) ? 'Ingresá tu provincia; ej.: Buenos Aires' : '',
    name: !isValidRequired(name.trim()) ? 'Ingresá tu nombre; ej.: Ariel Martinez' : '',
    email: !isValidRequired(email.trim())
      ? 'Ingresá tu email; ej.: nombre@dominio.com'
      : !isValidEmail(email.trim())
      ? 'Ingresá un email válido; ej.: nombre@dominio.com'
      : '',
    phone: !isValidRequired(phone.trim())
      ? 'Ingresá tu teléfono; ej.: 1122224444'
      : !isValidNumber(phone.trim())
      ? 'Ingresá un teléfono válido; ej.: 1122224444'
      : '',
  });

  return (
    <Form noValidate>
      <Input
        label="RUBRO"
        placeholder="Ej.: Gastronomía"
        value={category}
        error={errors.category}
        onChange={({ target }) => setCategory(target.value)}
      />
      <Input
        label="PROVINCIA"
        placeholder="Ej.: Buenos Aires"
        value={province}
        error={errors.province}
        onChange={({ target }) => setProvince(target.value)}
      />
      <Input
        label="NOMBRE"
        placeholder="Ej.: Ariel Martinez"
        value={name}
        error={errors.name}
        onChange={({ target }) => setName(target.value)}
      />
      <Input
        label="CORREO ELECTRÓNICO"
        placeholder="Ej.: nombre@dominio.com"
        value={email}
        type="email"
        info="Correo al que enviaremos tu póliza"
        error={errors.email}
        onChange={({ target }) => setEmail(target.value)}
      />
      <Input
        className={`phone-${props.countryCode}`}
        label="TELÉFONO CELULAR"
        placeholder="Ej.: 1122224444"
        value={phone}
        type="number"
        info="Celular para la gestión de tu seguro"
        error={errors.phone}
        onChange={({ target }) => setPhone(target.value)}
      />
      {submitted && !isEmpty(errors) ? (
        <div style={{ paddingBottom: '24px' }}>
          <Alert type="error" text="Por favor revisá los campos con error"></Alert>
        </div>
      ) : null}
      <Button
        block
        disabled={processing}
        onClick={async () => {
          setSubmitted(true);
          const formErrors = getErrors();
          setErrors(formErrors);
          if (!isEmpty(formErrors)) {
            return;
          }

          setProcessing(true);
          try {
            await Axios.post(`//${site.siteMetadata.apiDomain}/api/quote?product=shop`, {
              category,
              province,
              name,
              phone,
              email,
              countryCode: props.countryCode,
              url: document.URL,
            });
            setProcessing(false);
            window.scrollTo({ top: 0, behavior: 'smooth' });
            onRegistration({ category, province, name, email, phone });
          } catch (_) {
            setProcessing(false);
          }
        }}
      >
        Solicitar cotización
      </Button>
    </Form>
  );
};

export default ShopLeadForm;
